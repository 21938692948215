import React from 'react';
import { getClasses } from '../../utils/styler';

const MultipleOptionsInput = ({
	options,
	placeholder,
	onChangeInput,
	onChangeSelect,
	label,
	required,
	variant,
	disabled,
	inputValue,
	selectValue,
	...props
}) => {
	return (
		<div className={`doca-form__field ${required ? 'doca-form__field--required' : ''} doca-my-4`}>
			{label && (
				<label className={getClasses({ ...props }, {'doca-form__label': true})}>
					{label}
				</label>
			)}
			<div className="doca-phone-input">
				<select className="doca-form-control doca-phone-input__country" value={selectValue} onChange={onChangeSelect}>
					{options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
				<input
					className="doca-form-control doca-phone-input__phone"
					type="text"
					name={selectValue}
					placeholder={placeholder}
					onChange={onChangeInput}
					value={inputValue || ''}
					required={required}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default MultipleOptionsInput;
