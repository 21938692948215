import React from 'react';
import { Card } from '@amigoapp/doca-react';
import { AddressUtils, DateUtils } from '../../../utils';
import { Button } from '../../../components';
import { RemainingTimeContainer, RemainingTimeLabel } from './AttendanceItem.styles';

const AttendanceItem = ({ isAllowToManage, attendance, onEditClick, onCancelClick }) => {
	if (!attendance) {
		return null;
	}

	const { labelText, labelColor, labelTextColor } = attendance.remainingDaysInfo;

	return (
		<Card style={{ position: 'relative' }}>
			<Card.Content className="doca-pb-1 doca-flex doca-flex-col doca-gap-1">
				<div className="doca-flex doca-justify-between doca-items-center">
					<Card.Title className="doca-text--semibold doca-text--base" style={{ color: '#14191E' }}>
						{attendance?.event?.name}
					</Card.Title>
					<div className="doca-flex doca-justify-end doca-items-center">
						{isAllowToManage && (
							<>
								<Button
									variant="tertiary"
									size="small"
									onClick={() => onEditClick(attendance)}
								>
									<i className="doca-icon doca-icon--medium doca-icon__edit" />
								</Button>
								<Button
									variant="tertiary"
									size="small"
									onClick={() => onCancelClick(attendance)}
								>
									<i className="doca-icon doca-icon--medium doca-icon__trash doca-text--danger" />
								</Button>
							</>
						)}
					</div>
				</div>
				<Card.Subtitle className="doca-text--normal doca-text--small" style={{ color: '#3D4E5C' }}>
					<i className="doca-icon doca-icon--medium doca-icon__stethoscope doca-mr-2" />
					{attendance?.doctor?.name}
				</Card.Subtitle>
				<Card.Subtitle className="doca-text--normal doca-text--small" style={{ color: '#3D4E5C' }}>
					<i className="doca-icon doca-icon--medium doca-icon__location-dot doca-mr-2" />
					{attendance?.place?.name && attendance?.place?.address ? (
						<>
							{attendance?.place?.name} • {AddressUtils.formatAddress(attendance?.place)}
						</>
					) : (
						'Não informado'
					)}
				</Card.Subtitle>
				<Card.Subtitle className="doca-text--normal doca-text--small" style={{ color: '#3D4E5C' }}>
					<i className="doca-icon doca-icon--medium doca-icon__calendar-lines doca-mr-2" />
					{DateUtils.formatTime(attendance?.start_date, attendance?.arrival_start_time, 'dddd DD, MMM YYYY • HH:mm')} às{' '}
					{DateUtils.formatTime(attendance?.end_date, attendance?.arrival_end_time, 'HH:mm')}
				</Card.Subtitle>
			</Card.Content>
			<RemainingTimeContainer>
				<RemainingTimeLabel backgroundColor={labelColor} textColor={labelTextColor}>
					{labelText}
				</RemainingTimeLabel>
			</RemainingTimeContainer>
		</Card>
	);
};

export default AttendanceItem;
