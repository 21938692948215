import styled from 'styled-components';

export const ResponsibleTag = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 126px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0 6px 0;
  font-size: 12px;
  font-weight: bold;
  color: white;
`;
