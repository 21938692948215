import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import OTPValidationForm from './OTPValidationForm';
import { useCompanyStore, usePatientStore, useAnswersStore } from '../../stores';
import { validateOtp, generateOtp } from '../../processes/otp-codes';
import { showErrorToast, showSuccessToast } from '@amigoapp/doca-react';
import { formatPhone } from '../../utils/formatter';
import { NavigationUtils } from '../../utils';

function OTPValidationFormContainer() {
    const [loading, setLoading] = useState(true);
    const [remainingTime, setRemainingTime] = useState(0);
    const [otpVariant, setOtpVariant] = useState(null);
    const history = useHistory();
	const { setAnswer } = useAnswersStore();

	const { companySettings: { settings } } = useCompanyStore();
	const { patient, setPatient } = usePatientStore();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sendOtpCode = useCallback(async () => {
        setLoading(true);

        const { data, error } = await generateOtp({
			contact_cellphone: patient.contact_cellphone
		});

        setLoading(false);
		setOtpVariant(null);

        if (error) {
            showErrorToast('Erro ao enviar o OTP.');
        } else {
            setRemainingTime(data?.remaining_time);
        }
    }, [patient.contact_cellphone]);

    useEffect(() => {
        sendOtpCode();
    }, [sendOtpCode]);

    useEffect(() => {
        let timer;
        if (remainingTime > 0) {
            timer = setInterval(() => {
                setRemainingTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [remainingTime]);

    const handleSubmitPress = useCallback(async values => {
        setLoading(true);
        const mappedErrors = {
            FORBIDDEN: 'Erro ao validar o código.',
            EXCEEDED_MAX_ATTEMPTS: 'Você excedeu 3 tentativas de acesso, solicite um novo código de validação!'
        };

        const { data, error } = await validateOtp({
            otp_code: values.otp,
            contact_cellphone: patient.contact_cellphone
        });


        if (!data || error) {
            showErrorToast(mappedErrors[error.message] || 'Erro ao validar o código.');
            setOtpVariant('error');
			setLoading(false);

			return;
        }

		setPatient(data);
		setAnswer('patient', data.patient);

		NavigationUtils.navigate(history, '/patient-responsible');

		showSuccessToast('Código validado com sucesso.');
    }, [history, setPatient, patient.contact_cellphone, setAnswer]);

    const {
        handleSubmit,
        handleChange,
        isValid,
        values,
    } = useFormik({
        initialValues: {
            otp: ''
        },
        validate: values => {
            const errors = {};
            if (values.otp.length !== 6) {
                errors.otp = 'O código de validação deve ter 6 dígitos.';
            }
            return errors;
        },
        validateOnMount: true,
        onSubmit: handleSubmitPress
    });

    const handleOTPChange = useCallback(event => {
        setOtpVariant(null);
        handleChange(event);
    }, [handleChange]);

    const handleNavigateBackPress = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <OTPValidationForm
            values={values}
            loading={loading}
            onOTPChange={handleOTPChange}
            isValid={isValid}
            remainingTime={remainingTime}
            onSubmitClick={handleSubmit}
            handleNavigateBackPress={handleNavigateBackPress}
            variant={settings?.theme?.name}
            sendOtpCode={sendOtpCode}
            errorMessage={otpVariant === 'error' ? "Código de validação está incorreto" : null}
			patientNumber={formatPhone(patient?.contact_cellphone)}
        />
    );
}

export default OTPValidationFormContainer;
