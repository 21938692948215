import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const usePatientStore = create(
	persist(
		set => ({
			patient: null,
			setPatient: (data) => {
				set({
					patient: data.patient || null
				});
			},
			clearPatientData: () => {
				set({ patient: null });
			},
			removePatientAttendance: (attendanceId) => {
				set((state) => ({
					patient: {
						...state.patient,
						attendances: state?.patient?.attendances?.filter(
							attendance => attendance.id !== attendanceId,
						)
					}
				}));
			}
		}),
		{
			name: 'patient',
			partialize: (state) => ({
				patient: state.patient
			}),
		}
	)
);

export default usePatientStore;
