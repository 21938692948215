import * as moment from 'moment-timezone';

import React, { useMemo, useState } from 'react';
import { useCompanyStore } from '../../../stores';

import SlotItem from './SlotItem';

const noon = moment('12:00', 'HH:mm');
const nightStart = moment('18:00', 'HH:mm');

function SlotItemContainer({ data, selectedSlot, onSelectSlot }) {
	const { companySettings: { settings } } = useCompanyStore();
	const { slotsGrouped, slotIndex } = useMemo(() => {
		return {
			slotsGrouped: (data.slots || []).reduce(
				(acc, slot) => {
					const isMorning = moment(slot.start, 'HH:mm').isBefore(noon);
					const isNight = moment(slot.start, 'HH:mm').isSameOrAfter(nightStart);
					const key = isMorning ? 'MORNING' : isNight ? 'NIGHT' : 'AFTERNOON';

					return {
						...acc,
						[key]: [...acc[key], slot]
					};
				},
				{
					MORNING: [],
					AFTERNOON: [],
					NIGHT: []
				}
			),
			slotIndex: selectedSlot ? data.slots?.findIndex((slot) => slot.id === selectedSlot.id) : null
		};
	}, [data.slots, selectedSlot]);

	const [showMoreSlots, setShowMoreSlots] = useState(slotIndex > 2);

	return (
		<SlotItem
			showMoreSlots={showMoreSlots}
			onShowMoreSlots={setShowMoreSlots}
			slotsGrouped={slotsGrouped}
			user={data.user}
			variant={settings?.theme?.name}
			onSelectSlot={onSelectSlot}
			selectedSlot={selectedSlot}
		/>
	);
}

export default SlotItemContainer;
