import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useCompanyStore, useAnswersStore } from '../../stores';
import { NavigationUtils } from '../../utils';
import SelectEventGroupAndPaymentMethodForm from './SelectEventGroupAndPaymentMethodForm';

const PAYMENT_METHODS_ALLOWED = {
	PARTICULAR: 'PARTICULAR',
	INSURANCE: 'INSURANCE'
};

function SelectEventGroupAndPaymentMethodFormContainer() {
	const { companySettings: { scheduleSettings, settings } } = useCompanyStore();
	const { answers, setAnswer, clearInsurance } = useAnswersStore();
	const history = useHistory();

	const insurances = useMemo(
		() =>
			scheduleSettings?.insurance_plans?.filter(
				(insurance) => !answers?.insurance_group || insurance.health_insurance_group_id === ~~answers?.insurance_group
			),
		[answers?.insurance_group, scheduleSettings?.insurance_plans]
	);

	const handlePaymentMethodChange = useCallback(
		(method) => {
			setAnswer('payment_method', method);
			if (method === PAYMENT_METHODS_ALLOWED.PARTICULAR) {
				setAnswer('insurance_group', null);
				setAnswer('insurance', null);
				clearInsurance();
			}
		},
		[clearInsurance, setAnswer]
	);

	const handleSubmit = useCallback(() => {
		NavigationUtils.navigate(history, '/select-timegrid');
	}, [history]);

	return (
		<SelectEventGroupAndPaymentMethodForm
			selectedGroup={answers?.event_group || null}
			paymentMethod={answers?.payment_method || null}
			insuranceGroupId={answers?.insurance_group || null}
			insuranceId={answers?.insurance || null}
			onNextStepPress={handleSubmit}
			onSelectGroup={(group) => setAnswer('event_group', group)}
			onSelectInsuranceGroup={(id) => setAnswer('insurance_group', id)}
			onSelectPaymentMethod={handlePaymentMethodChange}
			onSelectInsurance={(id) => setAnswer('insurance', id)}
			groups={scheduleSettings?.event_groups}
			variant={settings?.theme?.name}
			paymentMethodsAllowed={PAYMENT_METHODS_ALLOWED}
			insurances={insurances}
			showInsurances={!!scheduleSettings?.insurance_plans?.length}
			insuranceGroups={scheduleSettings?.insurance_groups}
			patient={answers?.patient}
		/>
	);
}

export default SelectEventGroupAndPaymentMethodFormContainer;
