import { Card } from '@amigoapp/doca-react';
import { ResponsibleTag } from './PatientItem.styles.js';
import { FormatterUtils } from '../../../utils/index.js';
import { DateUtils } from '../../../utils/index.js';

const PatientItem = ({ patient, variant, handleCardClick }) => {
	return (
		<Card className="patient-card doca-card doca-cursor-pointer doca-mr-3 doca-w-full" onClick={handleCardClick}>
			<Card.Content className="doca-p-4 doca-flex doca-flex-col doca-gap-1">
				<div className="doca-flex doca-justify-between doca-items-center">
					<Card.Title className="doca-text--semibold doca-text--base" style={{ color: '#14191E' }}>
						{patient.name}
					</Card.Title>
				</div>
				<Card.Subtitle className="doca-text--normal doca-text--small doca-mt-1" style={{ color: '#3D4E5C' }}>
					<i className="doca-icon doca-icon--medium doca-icon__user doca-mr-2" />
					{FormatterUtils.formatCpf(patient?.cpf) || '-'}
				</Card.Subtitle>
				<Card.Subtitle className="doca-text--normal doca-text--small doca-mt-1" style={{ color: '#3D4E5C' }}>
					<i className="doca-icon doca-icon--medium doca-icon__calendar doca-mr-2" />
					{DateUtils.formatDate(patient.born, 'DD/MM/YYYY', 'YYYY-MM-DD') || '-'}
				</Card.Subtitle>
			</Card.Content>
			{patient.isMainPatient && (
				<ResponsibleTag className={`doca-bg-${variant}-pure`}>
					Você
				</ResponsibleTag>
			)}
		</Card>
	);
};

export default PatientItem;
