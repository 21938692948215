import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAnswersStore = create(
	persist(
		set => ({
			answers: {
				event_group: null,
				place: null,
				event: null,
				slot: null,
				user: null,
				insurance_group: null,
				insurance: null,
				payment_method: null,
				patient: null
			},
			setAnswer: (key, value) => {
				set((state) => ({
					answers: { ...state.answers, [key]: value }
				}));
			},
			setAnswers: (newAnswers) => {
				set({ answers: newAnswers });
			},
			appendAnswers: (newAnswers) => {
				set((state) => ({
					answers: { ...state.answers, ...newAnswers }
				}));
			},
			removeAnswersAttendance: (attendanceId) => {
				set((state) => ({
					answers: {
						...state.answers,
						patient: {
							...state.answers.patient,
							attendances: state?.answers?.patient?.attendances?.filter(
								attendance => attendance.id !== attendanceId,
							)
						}
					}
				}));
			},
			clearInsurance: () => {
				set((state) => ({
					answers: { ...state.answers, insurance_group: null, insurance: null }
				}));
			},
			clearEventSlotPlaceAndUser: () => {
				set((state) => ({
					answers: {
						...state.answers,
						place: null,
						event: null,
						slot: null,
						user: null
					}
				}));
			},
			clearAnswers: () => {
				set({
					answers: {
						event_group: null,
						place: null,
						event: null,
						slot: null,
						user: null,
						insurance_group: null,
						insurance: null,
						payment_method: null,
						patient: null
					}
				});
			}
		}),
		{
			name: 'answers',
			partialize: (state) => ({ answers: state.answers })
		}
	)
);

export default useAnswersStore;
