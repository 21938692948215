import styled from 'styled-components';

import { Text, FlatList } from '../../components';

export const Form = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

Form.Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

Form.FlexRow = styled.div`
	display: flex;
	flex-direction: ${({ direction = 'row' }) => direction};
	height: fit-content;
	width: 100%;
`;

Form.Header.BodyContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

Form.Body = styled.div`
	display: flex;
	flex-direction: column;
`;

Form.FlatList = FlatList;

Form.Header.Title = Text;

Form.Header.Subtitle = Text;
