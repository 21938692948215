import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import PatientUpdateDocumentForm from './PatientUpdateDocumentForm';
import { updateDocument } from '../../processes/patient';
import { showErrorToast } from '@amigoapp/doca-react';
import { clearMask } from '../../utils/formatter';
import { useCompanyStore, usePatientStore } from '../../stores';
import { NavigationUtils } from '../../utils';

function PatientUpdateDocumentFormContainer() {
	const [loading, setLoading] = useState(false);
	const { companySettings: { settings } } = useCompanyStore();
	const { patient, setPatient } = usePatientStore();
	const history = useHistory();

	const documentOptions = [
		{ value: 'cpf', label: 'CPF' },
		{ value: 'foreign_passport', label: 'Passaporte' },
		{ value: 'national_immigration_registry', label: 'CRNM (Carteira Nacional de Registro Migratório)' }
	]

	const handleSubmitPress = useCallback(async values => {
		setLoading(true);

		const cleanDocument = clearMask(values.cpf) || values.foreign_passport || values.national_immigration_registry;

		const { error } = await updateDocument({
			documentType: values.type,
			[values.type]: cleanDocument
		}, patient.updatePatientToken);

		if (error) {
			showErrorToast('Erro ao atualizar o documento.');
			setLoading(false);

			return
		}

		setPatient({
			patient: {
				...patient,
				[values.type]: cleanDocument,
			},
		});

		NavigationUtils.navigate(history, '/select-event-group-payment-method');
	}, [history, setPatient, patient]);

	const {
		handleSubmit,
		handleChange,
		isValid,
		setFieldValue,
		values
	} = useFormik({
		initialValues: {
			type: 'cpf'
		},
		validateOnMount: true,
		onSubmit: handleSubmitPress
	});

	const onChangeWithMask = useCallback((event, mask) => {
		if (event.target.value && mask) {
			event.target.value = mask(event.target.value);
		}

		return handleChange(event);
	}, [handleChange]);

	const handleSelectChange = useCallback((event) => {
		setFieldValue('type', event.target.value);
	}, [setFieldValue]);

	const handleNavigateBackPress = useCallback(() => {
		history.goBack();
	}, [history]);

	return (
		<PatientUpdateDocumentForm
			patient={patient}
			values={values}
			loading={loading}
			onChangeWithMask={onChangeWithMask}
			handleSelectChange={handleSelectChange}
			isValid={isValid}
			onChange={handleChange}
			variant={settings?.theme?.name}
			onSubmitClick={handleSubmit}
			setFieldValue={setFieldValue}
			handleNavigateBackPress={handleNavigateBackPress}
			documentOptions={documentOptions}
		/>
	);
}

export default PatientUpdateDocumentFormContainer;
