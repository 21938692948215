import React, { useCallback } from 'react';
import { Form as StyledForm } from './PatientResponsibleForm.styles.js';
import { Container, Button} from '../../components/index.js';
import { PatientItem } from '../../views';

function PatientResponsible({
	handleCreateNewDependent,
	variant,
	handleSelectPatient,
	listMaxHeight,
	patients
}) {
	const renderPatientItem = useCallback(
		(patient) => (
			<div className="doca-mb-6" key={patient.id}>
				<PatientItem
					patient={patient}
					handleCardClick={() => handleSelectPatient(patient)}
					variant={variant}
				/>
			</div>
		), [handleSelectPatient, variant]);

	const renderPatientKeyExtractor = (item) => item.id;

	return (
		<Container padded>
			<StyledForm.Header.Title
				size="extra-large"
				weight="medium"
				style={{ color: '#26282A' }}
			>
				Para quem será o agendamento?
			</StyledForm.Header.Title>

			<StyledForm.Header.Subtitle
				size="small"
				weight="medium"
				marginTop={8}
				style={{ color: '#6b6b6b' }}
			>
				Selecione o paciente que será atendido.
			</StyledForm.Header.Subtitle>

			<div className="doca-mt-6">
				{patients?.length > 0 ? (
					<StyledForm.FlatList
						data={patients}
						renderItem={renderPatientItem}
						renderItemKeyExtractor={renderPatientKeyExtractor}
						maxHeight={listMaxHeight}
						variant={variant}
					/>
				) : (
					<div className="doca-alert doca-alert--warning">
						Nenhum paciente cadastrado
					</div>
				)}
			</div>

			<Button
				variant="secondary"
				marginTop={24}
				block
				size="large"
				onClick={handleCreateNewDependent}
			>
				Novo paciente
			</Button>
		</Container>
	);
}

export default PatientResponsible;
