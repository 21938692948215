import React from "react";
import { Loading, Text, Button } from '../../components'
import { Input } from "@amigoapp/doca-react";
import { formatDate, formatPhone, formatCpf } from '../../utils/formatter';
import { MultipleOptionsInput } from '../../components';

const GENDER = {
	masculino: 'Masculino',
	feminino: 'Feminino'
};

function PatientForm({
	loading,
	isValid,
	values,
	variant,
	setFieldValue,
	onSubmitClick,
	onChange,
	onChangeWithMask,
	handleNavigateBack,
	documentOptions,
	handleSelectChange,
	handleCheckboxAddDependent,
	patient
}) {
	if (loading) {
		return <Loading />
	}
	return (
		<div className="doca-flex doca-p-4 doca-flex-col doca-w-full">
			<div className="doca-mb-4 doca-mt-2">
				<label className="doca-steps__label">
					Passo 3 de 3
				</label>

				<div className="doca-steps__container">
					<div className={`doca-steps doca-mr-3 doca-w-full doca-steps--done doca-steps--${variant}`} />
					<div className={`doca-steps doca-mr-3 doca-w-full doca-steps--done doca-steps--${variant}`} />
					<div className={`doca-steps doca-w-full doca-steps--current doca-steps--${variant}`} />
				</div>
			</div>

			<Text size="large" weight="medium">
				Dados Pessoais
			</Text>
			<Text size="small" weight="normal" variant="gray" marginBottom={16}>
				Preencha os dados pessoais para finalizar o agendamento
			</Text>

			<form onSubmit={onSubmitClick}>
				<Input
					label="Nome Completo"
					placeholder="Sem abreviações"
					name="name"
					className="doca-mb-4"
					required
					value={values?.name}
					onChange={onChange}
				/>

				<MultipleOptionsInput
					options={documentOptions}
					placeholder="Documento"
					label="Documento"
					selectValue={values?.type}
					inputValue={values[values.type]}
					onChangeSelect={event => handleSelectChange(event)}
					onChangeInput={event => onChangeWithMask(event, values?.type === 'cpf' ? formatCpf : null)}
					required={!values.add_dependent}
				/>

				{patient.cpf && (
					<div className="doca-checkbox__container">
						<input
							id="add_dependent"
							className="doca-checkbox doca-my-2"
							type="checkbox"
							name="add_dependent"
							onChange={handleCheckboxAddDependent}
							checked={values?.add_dependent || false}
						/>
						<label className="doca-form__label doca-form__label--inline">
							Adicionar como dependente
						</label>
					</div>
				)}

				<Input
					label="Data de nascimento"
					name="born"
					value={values?.born}
					className="doca-my-2"
					required
					type="tel"
					onChange={event => onChangeWithMask(event, formatDate)}
				/>

				<div className=" doca-form__field doca-form__field--required doca-mb-4">
					<label className="doca-form__label">
						Sexo
					</label>
					<div className="doca-flex doca-flex-row doca-justify-between doca-gap-4">
						<button type="button" className={`doca-shortcut w-100 ${values.gender === GENDER.masculino ? `doca-shortcut--${variant}` : ''}`} onClick={() => setFieldValue('gender', GENDER.masculino)}>
							<div>
								<i className="doca-icon doca-icon--large doca-icon__person" />

								<p className="doca-shortcut__title">
									Masculino
								</p>
							</div>
						</button>

						<button type="button" className={`doca-shortcut w-100 ${values.gender === GENDER.feminino ? `doca-shortcut--${variant}` : ''}`} onClick={() => setFieldValue('gender', GENDER.feminino)}>
							<div>
								<i className="doca-icon doca-icon--large doca-icon__person-dress" />

								<p className="doca-shortcut__title">
									Feminino
								</p>
							</div>
						</button>
					</div>
				</div>

				<Input
					label="Celular"
					name="contact_cellphone"
					value={values?.contact_cellphone}
					type="tel"
					required
					className="doca-mb-4"
					maxLength={15}
					onChange={event => onChangeWithMask(event, formatPhone)}
					placeholder="Digite com DDD"
				/>

				<Input
					label="E-mail"
					name="email"
					type="email"
					required
					className="doca-mb-4"
					value={values?.email}
					onChange={onChange}
				/>

				<div className="doca-checkbox__container doca-mb-4">
					<input
						id="terms-of-use"
						className="doca-checkbox"
						type="checkbox"
						name="terms_of_use"
						onChange={onChange}
						checked={!!values.terms_of_use?.includes('on')}
					/>

					<label htmlFor="terms-of-use" className="doca-form__label doca-form__label--inline">
						Aceito os <a href="https://amigoapp.tech/clinic/termos-de-uso/" className="doca-link" target="_blank" rel="noreferrer">Termos de uso</a> e <a href="https://amigoapp.tech/clinic/politica-de-privacidade/" className="doca-link" target="_blank" rel="noreferrer">Privacidade</a>.
					</label>
				</div>

				<Button
					block
					size="large"
					disabled={!isValid}
					marginBottom={16}
					variant={variant}
				>
					Realizar Agendamento
				</Button>
				<Button
                    block
                    size="large"
                    onClick={handleNavigateBack}
                    variant='secondary'
                    type="button"
                >
                    <i className="doca-icon doca-icon__arrow-left"></i>
                    Voltar
                </Button>
			</form>
		</div>
	);
}

export default PatientForm;
