import { ToastContainer } from '@amigoapp/doca-react';
import moment from 'moment-timezone';
import { Route, Switch, withRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import '../node_modules/@amigoapp/doca/dist/css/doca.min.css';

import {
	HomeView,
	PatientFormView,
	SelectTimegridFormView,
	ScheduledSuccessfullyView,
	UpdateTimegridFormView,
	PatientExistsFormView,
	OTPValidationFormView,
	SelectEventGroupAndPaymentMethodFormView,
	PatientNextAttendancesFormView,
	PatientResponsibleFormView,
	PatientUpdateDocumentFormView
} from './views';

import { NavigationUtils } from './utils';
import { useHistory } from 'react-router-dom';

import AppContainer from './views/_shared/AppContainer';

import 'moment/locale/pt-br';

moment.tz.setDefault('America/Recife');
moment.locale('pt-br');

moment.defineLocale('pt-br', {
	weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
});

const TOAST_OPTIONS = {
	position: 'bottom-center',
	autoClose: 2500,
	hideProgressBar: true,
	newestOnTop: false,
	closeOnClick: true,
	rtl: false,
	pauseOnFocusLoss: true,
	draggable: true,
	pauseOnHover: true
};

const ProtectedRoute = Component => {
	const history = useHistory();

	const token = localStorage.getItem('token');
	const companySettings = localStorage.getItem('companySettings');

	if (!token || !companySettings) {
		return NavigationUtils.navigate(history, '/');
	}

	return <Component />;
};

const App = () => {
	return <>
		<ToastContainer {...TOAST_OPTIONS} />

		<AppContainer>
			<Switch>
				<Route path="/patient-form" component={() => ProtectedRoute(PatientFormView)}></Route>
				<Route path="/select-timegrid" component={() => ProtectedRoute(SelectTimegridFormView)}></Route>
				<Route path="/scheduled-succesfully" component={() => ProtectedRoute(ScheduledSuccessfullyView)}></Route>
				<Route path="/update-timegrid/:token" component={() => ProtectedRoute(UpdateTimegridFormView)}></Route>
				<Route path="/patient-exists" component={() => ProtectedRoute(PatientExistsFormView)}></Route>
				<Route path="/otp-validation" component={() => ProtectedRoute(OTPValidationFormView)}></Route>
				<Route path="/select-event-group-payment-method" component={() => ProtectedRoute(SelectEventGroupAndPaymentMethodFormView)}></Route>
				<Route path="/patient-next-attendances" component={() => ProtectedRoute(PatientNextAttendancesFormView)}></Route>
				<Route path="/patient-responsible" component={() => ProtectedRoute(PatientResponsibleFormView)}></Route>
				<Route path="/patient-update-document" component={() => ProtectedRoute(PatientUpdateDocumentFormView)}></Route>
				<Route path="/" component={HomeView}></Route>
			</Switch>
		</AppContainer>
	</>;
};

export default withRouter(App);
