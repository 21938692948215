import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useCompanyStore = create(
	persist(
		set => ({
			companySettings: { settings: {}, scheduleSettings: {} },
			setCompanySettings: (newSettings) => {
				set((state) => ({
					companySettings: { ...state.companySettings, ...newSettings }
				}));
			},
			clearCompanySettings: () => {
				const defaultSettings = { settings: {}, scheduleSettings: {} };
				set({ companySettings: defaultSettings });

				['token', 'slug'].forEach((key) => localStorage.removeItem(key));
			}
		}),
		{
			name: 'companySettings',
			partialize: (state) => ({ companySettings: state.companySettings })
		}
	)
);

export default useCompanyStore;
