import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useCompanyStore, usePatientStore, useAnswersStore } from '../../stores';
import { NavigationUtils, WindowDimensionsUtils } from '../../utils';
import PatientExistsForm from './PatientResponsibleForm';

const footerPlusHeaderHeight = 344;

function PatientResponsibleContainer() {
	const { companySettings: { settings } } = useCompanyStore();
	const { patient } = usePatientStore();
	const { answers, setAnswers, clearAnswers } = useAnswersStore();
	const dimensions = WindowDimensionsUtils.useWindowDimensions();
	const listMaxHeight = useMemo(() => (!dimensions?.height ? 300 : dimensions?.height - footerPlusHeaderHeight), [dimensions?.height]);
	const history = useHistory();

	const listAllPatients = useCallback(() => {
		const dependents = patient.dependents?.map((dependent) => ({
			...dependent,
			isMainPatient: false,
		})) || [];

		return [{ ...patient, isMainPatient: true }, ...dependents];
	}, [patient]);

	const patients = useMemo(() => listAllPatients(), [listAllPatients]);

	const handleSelectPatient = useCallback((selectedPatient) => {
		if (!selectedPatient) {
			return;
		}

		setAnswers({
			patient: selectedPatient
		})

		const path = selectedPatient.attendances?.length > 0 ? `/patient-next-attendances` : 'select-event-group-payment-method';

		NavigationUtils.navigate(history, path);
	}, [history, setAnswers]);

	const handleCreateNewDependent = useCallback(() => {
		const patientToUpdate = !patient.cpf && !patient.foreign_passport && !patient.national_immigration_registry;

		clearAnswers();

		if (patientToUpdate) {
			return NavigationUtils.navigate(history, '/patient-update-document');
		}

		return NavigationUtils.navigate(history, '/select-event-group-payment-method');
	}, [history, patient, clearAnswers]);

	return (
		<PatientExistsForm
			variant={settings?.theme?.name}
			patients={patients}
			handleCreateNewDependent={handleCreateNewDependent}
			handleSelectPatient={handleSelectPatient}
			listMaxHeight={listMaxHeight}
			answers={answers}
		/>
	);
}

export default PatientResponsibleContainer;
