import React from 'react';
import { Loading, Container, Text, Button } from '../../components';
import { MultipleOptionsInput } from '../../components';
import { formatCpf } from '../../utils/formatter';

function PatientUpdateDocumentForm({
	loading,
	isValid,
	values,
	onSubmitClick,
	variant,
	patient,
	documentOptions,
	onChangeWithMask,
	handleSelectChange,
	handleNavigateBackPress
}) {
	if (loading) {
		return <Loading />;
	}

	return (
		<Container padded>
			<Text size="large" weight="semibold" marginBottom={8}>Atualização cadastral</Text>

			<Text variant="gray">
				Para prosseguir com o agendamento, cadastre o documento de {patient?.name}
			</Text>

			<form onSubmit={onSubmitClick}>

				<MultipleOptionsInput
					options={documentOptions}
					placeholder="Documento"
					label="Documento"
					selectValue={values?.type}
					inputValue={values[values.type]}
					onChangeSelect={event => handleSelectChange(event)}
					onChangeInput={event => onChangeWithMask(event, values?.type === 'cpf' ? formatCpf : null)}
					required
				/>

				<Button
					block
					size="large"
					disabled={!isValid}
					marginBottom={16}
					variant={variant}
					type="submit"
					marginTop={16}
				>
					Atualizar dados cadastrais
				</Button>

				<Button
					block
					size="large"
					onClick={handleNavigateBackPress}
					variant='secondary'
					type="button"
				>
					<i className="doca-icon doca-icon__arrow-left"></i>
					Voltar
				</Button>
			</form>
		</Container>
	);
}

export default PatientUpdateDocumentForm;
