import httpClient from '../utils/httpClient';
import toSnakeCase from '../utils/objectToSnakeCase';
import { getAuthToken } from '../utils/utils';

export const create = async patientData => {
	const { data, error } = await httpClient({
		method: 'post',
		url: `/patients`,
		data: toSnakeCase(patientData),
		headers: {
			authorization: getAuthToken()
		}
	});

	return { data, error };
};

export const patientExists = async filter => {
	const { data, error } = await httpClient({
		method: 'get',
		url: `/patients/${filter.contact_cellphone}/exists`,
		headers: {
			authorization: getAuthToken()
		}
	});

	return { data, error };
};

export const updateDocument = async (patientData, updateToken) => {
	const { data, error } = await httpClient({
		method: 'put',
		url: '/patients',
		data: toSnakeCase(patientData),
		headers: {
			authorization: `Bearer ${updateToken}`
		}
	});

	return { data, error }
};
