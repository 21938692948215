import React from 'react';
import { Loading, Container, Text, Button, InputField } from '../../components';
import { formatPhone } from '../../utils/formatter';

function PatientExistsForm({
	loading,
	isValid,
	values,
	onChangeWithMask,
	onSubmitClick,
	variant,
	handleNavigateBackPress
}) {
	if (loading) {
		return <Loading />;
	}

return (
	<Container padded>
		<Text size="large" weight="semibold" marginBottom={8}>Dados pessoais</Text>

		<Text marginBottom={24} variant="gray">
			Preencha o número do seu telefone para dar continuidade no agendamento
		</Text>

		<form onSubmit={onSubmitClick}>
			<InputField
				label="Celular"
				name="contact_cellphone"
				value={values?.contact_cellphone}
				type="tel"
				maxLength={15}
				className="doca-form__field doca-form__field--required"
				required
				onChange={event => onChangeWithMask(event, formatPhone)}
				placeholder="(00) 00000-0000"
			/>

			<Button
				block
				size="large"
				disabled={!isValid}
				marginBottom={16}
				variant={variant}
				type="submit"
				marginTop={24}
			>
				Continuar
			</Button>

			<Button
				block
				size="large"
				onClick={handleNavigateBackPress}
				variant='secondary'
				type="button"
			>
				<i className="doca-icon doca-icon__arrow-left"></i>
				Voltar
			</Button>
		</form>
	</Container>
	);
}

export default PatientExistsForm;
